@tailwind base;
@tailwind components;
@tailwind utilities;

.light > *::-webkit-scrollbar-track {
  background: #fff;
}


.dark {
  color: #dfdfdf;
}

.dark > *::-webkit-scrollbar-track {
  background: #1a1b25;
}

.light *::-webkit-scrollbar-thumb {
  background-color: rgba(148, 196, 242, 0.1);
  border-radius: 10px;
  border: 1px solid #b2b6ce;
}

.dark * {
  scrollbar-width: 10px;
  scrollbar-color:  #9aa2ac #1a1b25;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}
.dark *::-webkit-scrollbar-track {
  background-color: #1a1b25;
  border-color: #9aa2ac;
}

.dark *::-webkit-scrollbar-thumb {
  background-color: #1a1b25;
  border-radius: 10px;
  border: 1px solid #9aa2ac;
}

.dark.bg-main-bg:after {
  content: ' ';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #1a1b25;
  z-index: -1;
}

* {
  font-family: 'Lato', sans-serif;
}
.bearish {
  background-color: rgb(185, 28, 28);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}
.bullish {
  background-color: rgb(21, 128, 61);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}

.blue-flame {
  color: rgb(56, 189, 248);
}

.MuiTableRow-root {
  background-color: #fff;
}

/*.highlightChange {
  background-color: #f8ffb4;
  transition: all 0.5s ease-in-out;
}

.highlightChangeOut {
  background-color: #fff !important;
  transition: all 0.5s ease-in-out;
}
.onlyHighlightOnce {
  background-color: #fff !important;
  transition: all 0.5s ease-in-out;
}*/

/* .filter-row {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
} */

/* .filter-btns {
  width: 90%;
  margin: 10px auto;
} */

.filter-btns .bearish:hover,
.filter-btns .bearish.active {
  background-color: rgb(248, 113, 113);
}

.filter-btns .bullish:hover,
.filter-btns .bullish.active {
  background-color: rgb(74, 222, 128);
}

.MuiSwitch-switchBase input,
.MuiSwitch-thumb {
  background-color: rgb(56 189 248) !important;
}
.MuiTouchRipple-root,
.MuiSwitch-thumb {
  color: rgb(56 189 248) !important;
}

.MuiTableCell-body {
  /* padding: 10px !important; */
  padding: 7px 1px !important;
  font-size: 13px !important;
}

/* .MuiTableCell-head span {
  white-space: nowrap;
} */

/* .MuiTablePagination-selectLabel,
.MuiTablePagination-select,
.MuiTablePagination-displayedRows,
.MuiTablePagination-menuItem,
.filter-label,
.checkbox-label {
  font-size: 14px !important;
} */

.MuiTablePagination-select {
  display: flex !important;
  align-items: center !important;
}

.MuiTablePagination-actions svg {
  height: 24px !important;
  width: 24px !important;
}

.dark .MuiTablePagination-selectLabel,
.dark .MuiTablePagination-select,
.dark .MuiTablePagination-displayedRows,
.dark .MuiTablePagination-menuItem,
.dark .MuiTablePagination-actions svg,
.dark .MuiTablePagination-selectIcon {
  color: #b2b6ce !important;
  fill: #b2b6ce !important;
}

.range-label,
.bearish span,
.bullish span,
input::placeholder {
  font-size: 13px !important;
}
.tag-name {
  font-size: 11px !important;
  color: #70769b;
}
.dark .tag-name.no-style {
  color: #b2b6ce !important;
  border-color: #b2b6ce !important;
}
.dark .no-style-wrap:hover .no-style {
  color: #1a1b25 !important;
}

/* .scanner {
  max-height: 100vh;
  height: 100vh;
} */

.Pane {
  flex-basis: 1 !important;
}

.multi-screen-scanner .group-title {
  max-width: 100% !important;
  width: 100% !important;
}
.multi-screen-scanner .scanner-group-header,
.multi-screen-scanner .delay-info {
  max-width: 95% !important;
  width: 95% !important;
}
.after-market-hours {
  font-weight: 600;
  font-size: 19px;
  color: #70769b;
  font-style: italic;
}
.dark .after-market-hours {
  color: #70769b;
}

.SplitPane.vertical {
  max-height: 95% !important;
}
.SplitPane.horizontal {
  max-width: 99% !important;
}

.Pane.horizontal,
.SplitPane .Pane.vertical:first-of-type {
  overflow-y: scroll;
}

#scan_scanner {
  min-height: 100vh;
  position: relative;
}

.react-datepicker-wrapper {
  display: flex;
  align-items: center;
}
.react-datepicker__input-container {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  --tw-shadow: 0;
  --tw-shadow-colored: 0;
  box-shadow: 0;
  display: flex;
  align-items: center;
}
.react-datepicker__input-container input {
  --tw-border-opacity: 1;
  padding: 0;
  border: 0;
  margin-top: 0 !important;
  --tw-text-opacity: 1;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #70769b;
  width: 90px;
}

.react-datepicker__input-container input:focus {
  outline: transparent;
  --tw-ring-color: transparent;
}

.multi-screen-scanner .not-active svg {
  display: none;
  height: 0;
  width: 0;
}

.multi-screen-scanner .MuiTableCell-head,
.multi-screen-scanner .MuiTableRow-root .MuiTableCell-body {
  font-size: 12px !important;
  padding: 8px 3px !important;
}

.multi-screen-scanner .range-label-multi {
  font-size: 11px !important;
}

.multi-screen-scanner .MuiTableContainer-root,
.multi-screen-scanner .MuiTable-root {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg); /* Mozilla */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  -ms-transform: rotateX(180deg); /* IE 9+ */
  -o-transform: rotateX(180deg); /* Opera */
}

.Pane.vertical {
  overflow-y: auto;
}
.Pane.horizontal {
  overflow-x: auto;
}

.scanner .MuiPaper-elevation {
  box-shadow: none !important;
  margin-bottom: 0 !important;
}

.dark .scanner .MuiPaper-elevation,
.dark .MuiTableRow-head,
.dark .MuiTableRow-head .MuiTableCell-head {
  background: #1a1b25;
}

.MuiTableRow-head .MuiTableCell-head {
  padding: 7px 1px !important;
  /* padding: 10px !important; */
}

.MuiTableRow-head {
  border-top: 0.5px solid #b2b6ce !important;
  border-bottom: 0.5px solid #b2b6ce !important;
}
.dark .MuiTableRow-head {
  border-top: 0.5px solid #b2b6ce !important;
  border-bottom: 0.5px solid #b2b6ce !important;
}

.MuiTableCell-head {
  border-top: 0.5px solid #b2b6ce !important;
  border-bottom: 0.5px solid #b2b6ce !important;
}
.MuiTableCell-stickyHeader {
  top: -1px !important;
}

.symbol-item {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #0c3c0c;
}
.is-clickable .symbol-item{
  cursor: pointer;
}

.dark .symbol-item {
  color: #41e241;
}

.scanner-item span:not(.color-item, .symbol-item) {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #70769b;
}

.dark .scanner-item span:not(.color-item, .symbol-item) {
  color: #b2b6ce;
}

.color-item {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.dark .color-item {
  color: #fff;
}
.dark .no-color-item {
  color: #70769b !important;
}

.override-row-color .no-color-item {
  color: #080b0c !important;
  font-weight: 700 !important;
}

.MuiTableRow-head .MuiTableCell-head,
.table-header-light {
  font-family: 'Lato', sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #70769b !important;
}

.table-header-light svg {
  color: #70769b !important;
}

.MuiTableBody-root .MuiTableRow-root:nth-child(even) {
  background: rgba(0, 116, 223, 0.02) !important;
}
.MuiTableBody-root .MuiTableRow-root:nth-child(even):hover,
.pagination-button:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.dark .MuiTableBody-root .MuiTableRow-root:nth-child(odd) {
  background: #1a1b25 !important;
}
.dark .MuiTableBody-root .MuiTableRow-root:nth-child(even) {
  background: rgba(112, 118, 155, 0.15) !important;
}

/* .MuiTableBody-root .MuiTableRow-root {
  border: none !important;
  border-top: 7.5px solid transparent !important;
  border-bottom: 7.5px solid transparent !important;
} */

.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  border: none !important;
}

.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root .MuiTableCell-body {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.MuiTable-root {
  border-collapse: collapse !important;
  border-spacing: 0 15px !important;
}

.main-container {
  overflow-y: scroll;
  max-height: 100vh;
  height: 100vh;
}
.sidebar {
  height: 100vh;
}
.sidebar.sidebar-open a.active {
  color: white;
}
.sidebar.sidebar-open a.active {
  background: #0c3c0c;
}
.dark .sidebar a.active {
  color: #0c3c0c;
  background: #41e241;
}

.sidebar.sidebar-open path,
.sidebar.sidebar-open circle {
  fill: #9AA2AC;
}
.dark .sidebar.sidebar-open path,
.dark .sidebar.sidebar-open circle {
  fill: #9AA2AC;
}
.sidebar.sidebar-close a.active.sidebar-item-wrap:hover path
.sidebar.sidebar-close a.active.sidebar-item-wrap:hover circle,
.sidebar.sidebar-open button.active.sidebar-tiem-wrap:hover path,
.sidebar.sidebar-open button.active.sidebar-item-wrap:hover circle,
.sidebar.sidebar-close button.active.sidebar-tiem-wrap:hover path,
.sidebar.sidebar-close button.active.sidebar-item-wrap:hover circle {
  fill: #fff;
}
.sidebar a:hover path,
.sidebar button:hover path {
  fill: #fff;
}
.sidebar.sidebar-close a.active path,
.sidebar.sidebar-close a.active circle {
  fill: #0c3c0c;
}
.light .sidebar.sidebar-close a.active.sidebar-item-wrap:hover path,
.light .sidebar.sidebar-close a.active.sidebar-item-wrap:hover circle {
  fill: #0c3c0c;
}

.dark .sidebar a.active path,
.dark .sidebar a.active circle {
  fill: #0c3c0c;
}

.MuiTablePagination-actions {
  display: none;
}

/* User Loading  */
#loading-user {
  top: 0;
  left: 0;
  color: #464b45;
}
#loading-user img {
  width: 65px;
}

.dark #loading-user {
  background: #252634;
  color: #dfdfdf;
}

/* Pages  */
#page {
  padding: 0 1rem;
  width: 90%;
  max-width: 1200px;
  margin: auto;
}
#page h1 {
  font-size: 2.5rem;
  padding-top: 25px;
  color: #464b45;
}
#page a {
  color: #41e241;
  text-decoration: underline;
}

/** Fixed Chart */
.chart-container {
  position: sticky;
  background: #fff;
  z-index: 9999;
  width: 100%;
  margin-top: -2.25rem;
  padding-top: 20px;
  padding-bottom: 20px;
  top: -2.25rem;
}

.chart-container .chart {
  width: 100%;
  position: relative;
}

.chart-visible {
  /* margin-top: 350px; */
}

.dark-mode-toggle {
  position: absolute;
  left: auto;
  right: 1rem;
}

.sidebar-close {
  width: 40px;
  position: static;
  max-width: 40px;
}

.light .close-sidebar-button svg path {
  fill: black;
}

.download-csv-link {
  position: absolute;
  bottom: 40px;
}

@media only screen and (max-width: 1080px) {
  .sidebar {
    display: none !important;
  }
  .filter-wrapper {
    flex-wrap: wrap;
    gap: 10px;
  }
}
@media only screen and (max-width: 900px) {
  /* .dark-mode-toggle {
    left: 1rem;
    right: auto;
  }
  .filter-wrapper {
    padding-top: 10px;
  } */
  .MuiTableCell-body {
    padding: 7px 5px !important;
  }
}

@media only screen and (max-width: 600px) {
  .date-pick-wrap {
    flex: 1 0 100%;
  }
}

@media only screen and (max-width: 475px) {
  .dark-mode-toggle {
    left: 1rem;
    right: auto;
  }
  .filter-wrapper {
    padding-top: 30px;
  }
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.powered-by {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: -5px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  position: absolute;
  right: 15px;
  z-index: 9999999;
}

.powered-by a {
  text-align: right;
  font-family: 'Lato', sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 15px !important;
  color: #70769b !important;
}

.powered-by a:hover {
  text-decoration: underline;
}

.dark .access-denied {
  color: #fff !important;
}

.react-tabs {
  width: 100% !important;
}

.ReactModalPortal .ReactModal__Overlay {
  z-index: 99999999999999 !important;
}

/* .ReactModal__Content  {
  max-height: 400px;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  transform: translate(-50%, -50%) !important;
} */

.dark .ReactModal__Content {
  background: #1a1b25 !important;
}

.dark .ReactModalPortal .ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.react-tabs__tab-list .react-tabs__tab {
  font-family: 'Lato', sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #70769b !important;
  padding: 4px 10px !important;
  /* 0.5px solid #b2b6ce !important */
}
.react-tabs__tab--selected {
  border-color: #b2b6ce !important;
}

.react-tabs__tab-list {
  border-color: #b2b6ce !important;
  margin-bottom: 0 0 8px !important;
}

.dark .react-tabs__tab--selected {
  background: #1a1b25 !important;
}

.dark .react-tabs__tab-list .react-tabs__tab {
  color: #70769b !important;
}

.dark .react-tabs__tab:focus:after {
  background: #1a1b25 !important;
}

.close-modal-wrap {
  position: absolute;
  left: auto;
  right: 20px;
  z-index: 99999;
  top: 5px;
}

.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
  margin: 0 auto;
}

.dark .loader {
  animation: darkl5 1s infinite linear alternate !important;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #1a1b25, -20px 0 #0002;
    background: #1a1b25;
  }

  33% {
    box-shadow: 20px 0 #1a1b25, -20px 0 #0002;
    background: #0002;
  }

  66% {
    box-shadow: 20px 0 #0002, -20px 0 #1a1b25;
    background: #0002;
  }

  100% {
    box-shadow: 20px 0 #0002, -20px 0 #1a1b25;
    background: #1a1b25;
  }
}

@keyframes darkl5 {
  0% {
    box-shadow: 20px 0 #909196, -20px 0 #F5FAFF;
    background: #909196;
  }

  33% {
    box-shadow: 20px 0 #909196, -20px 0 #F5FAFF;
    background: #F5FAFF;
  }

  66% {
    box-shadow: 20px 0 #F5FAFF, -20px 0 #909196;
    background: #F5FAFF;
  }

  100% {
    box-shadow: 20px 0 #F5FAFF, -20px 0 #909196;
    background: #909196;
  }
}

.no-res {
  font-size: 24px;
  text-align: left;
  color: #70769b;
}
.dark .no-res {
  color: #b2b6ce;
}

.reset-filter-range {
  font-size: 12px;
  color: #70769b;
  cursor: pointer;
  border: none;
}


.vertical-stack-layout .scanner .MuiBox-root {
  margin: 0;
  width: 100%;
}
.vertical-stack-layout .main-group-header {
  margin: 10px 0;
  width: 100%;
  display: flex;
  gap: 2.25rem;
  align-items: center;
}
.sidebar-layout .main-group-header {
  margin-right: 125px;
}

.vertical-stack-layout .group-name {
  margin: 10px 0;
}

.vertical-stack-layout .horizontal.SplitPane {
  top: 60px;
}

.vertical-stack-layout .multi-screen-scanner .delay-info{
  width: 100% !important;
  max-width: 100% !important;
}

.vertical-stack-layout .filter-wrapper div:first-of-type {
  display: flex;
  align-items: center;
}

.secondary-header {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 0.5rem;
}

.vertical-stack-layout .secondary-header {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
}

.react-tabs {
  width: 100% !important;
}

.react-tabs__tab-list .react-tabs__tab {
  font-family: 'Lato', sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #70769b !important;
  padding: 4px 10px !important;
  /* 0.5px solid #b2b6ce !important */
}
.react-tabs__tab--selected {
  border-color: #b2b6ce !important;
}

.react-tabs__tab-list {
  border-color: #b2b6ce !important;
  margin-bottom: 0 0 8px !important;
}

.dark .react-tabs__tab--selected {
  background: #1a1b25 !important;
}

.dark .react-tabs__tab-list .react-tabs__tab {
  color: #70769b !important;
}

.dark .react-tabs__tab:focus:after {
  background: #1a1b25 !important;
}

.react-tabs__tab-panel .scanner .MuiBox-root{
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}


.power-options {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.57);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.power-options h1 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
}

.option-wrap {
  display: flex;
}

.option-label {
  padding: 10px 20px;
  border-radius: 10px;
}

.option-label span {
  color: #fff !important;
  font-size: 18px;
  line-height: 21px;
  font-weight: 800;
}

.open-options {
  color: #b2b6ce;
}

.open-options svg {
  height: 15px;
  width: 15px;
}


.lds-ring-date {
  /* change color here */
  color: #b2b6ce
}
.lds-ring-date,
.lds-ring-date div {
  box-sizing: border-box;
}
.lds-ring-date {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}
.lds-ring-date div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 2px;
  border: 2px solid currentColor;
  border-radius: 50%;
  animation: lds-ring-date 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring-date div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-date div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-date div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring-date {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.lds-dual-ring-main,
.lds-dual-ring-main:after {
  box-sizing: border-box;
}
.lds-dual-ring-main {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring-main:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: lds-dual-ring-main 1.2s linear infinite;
}
@keyframes lds-dual-ring-main {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.report .report-table .report-item {
  color: rgb(55 65 81);
}

.dark .report .report-table .report-item {
  color: rgb(209 213 219);
}

.reports-page-loading {

}

.reports-page-loading .lds-ellipsis,
.reports-page-loading .lds-ellipsis div {
  box-sizing: border-box;
}
.reports-page-loading .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.reports-page-loading .lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.reports-page-loading .lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: reports-page-lds-ellipsis1 0.6s infinite;
}
.reports-page-loading .lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: reports-page-lds-ellipsis2 0.6s infinite;
}
.reports-page-loading .lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: reports-page-lds-ellipsis2 0.6s infinite;
}
.reports-page-loading .lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: reports-page-lds-ellipsis3 0.6s infinite;
}
@keyframes reports-page-lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes reports-page-lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes reports-page-lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.react-tabs .react-tabs__tab-list,
.dark .react-tabs .react-tabs__tab-list {
  max-width: 93% !important;
}